<template>
  <div class="layout-config h-100 p-10">
    <h3>{{ $route.meta.title }}</h3>
    <Row :gutter="10" class="m-t-10">
      <Col span="8">
        <Card title="筒灯设置" class="">
          <Form
            ref="downLightForm"
            :model="downLightForm"
            label-position="left"
            :label-width="80"
            label-colon
          >
            <FormItem label="控制开关">
              <RadioGroup v-model="downLightForm.state">
                <Radio label="ON">开</Radio>
                <Radio label="OFF">关</Radio>
                <Radio label="TOGGLE">切换</Radio>
              </RadioGroup>
              <Button
                type="warning"
                size="small"
                icon="md-close"
                @click="downLightForm.state = null"
                v-if="downLightForm.state"
              ></Button>
            </FormItem>
            <FormItem label="控制亮度">
              <Slider
                v-model="downLightForm.brightness"
                :step="1"
                :min="0"
                :max="254"
              />
            </FormItem>
            <FormItem label="颜色模式">
              <RadioGroup v-model="downLightForm.colorMode">
                <Radio label="xy">彩光</Radio>
                <Radio label="color_temp">白光</Radio>
              </RadioGroup>
              <Button
                type="warning"
                size="small"
                icon="md-close"
                @click="downLightForm.colorMode = null"
                v-if="downLightForm.colorMode"
              ></Button>
            </FormItem>
            <FormItem
              label="控制色温"
              v-if="downLightForm.colorMode == 'color_temp'"
            >
              <Slider
                v-model="downLightForm.colorTemp"
                :step="1"
                :min="153"
                :max="500"
              />
            </FormItem>
            <FormItem label="控制灯光" v-if="downLightForm.colorMode == 'xy'">
              <input v-model="downLightForm.color" type="color" />
            </FormItem>
            <FormItem label="灯效">
              <SelectDict
                v-model="downLightForm.effect"
                dict="effect"
                class="w-60"
              />
            </FormItem>
            <FormItem label="过渡">
              <InputNumber v-model="downLightForm.transition" />
            </FormItem>
            <FormItem label="渐变">
              <template v-if="downLightForm.colorMode == 'xy'">
                亮度：<InputNumber v-model="downLightForm.brightness_move" />
                亮度变化：
                <InputNumber v-model="downLightForm.brightness_step" />
              </template>
              <template v-else-if="downLightForm.colorMode == 'color_temp'">
                色温：<InputNumber
                  v-model="downLightForm.color_temp_move"
                  :min="153"
                  :max="500"
                />
                色温变化：
                <InputNumber v-model="downLightForm.color_temp_step" />
              </template>
              <span class="f-10" v-else>请先选择颜色模式！</span>
            </FormItem>
          </Form>
          <Button
            type="info"
            @click="saveDownLight('downLightForm', downLightForm.id)"
            >设置</Button
          >
          <Button
            @click="resetForm('downLightForm', downLightForm.id)"
            class="m-l-5"
            >重置</Button
          >
        </Card>
      </Col>
      <Col span="8">
        <Card title="灯带设置" class="">
          <Form
            ref="lightBeltForm"
            :model="lightBeltForm"
            label-position="left"
            :label-width="120"
            label-colon
          >
            <FormItem label="控制开关">
              <RadioGroup v-model="lightBeltForm.state">
                <Radio label="ON">开</Radio>
                <Radio label="OFF">关</Radio>
                <Radio label="TOGGLE">切换</Radio>
              </RadioGroup>
              <Button
                type="warning"
                size="small"
                icon="md-close"
                @click="lightBeltForm.state = null"
                v-if="lightBeltForm.state"
              ></Button>
            </FormItem>
            <FormItem label="控制亮度">
              <Slider
                v-model="lightBeltForm.brightness"
                :step="1"
                :min="0"
                :max="254"
              />
            </FormItem>
            <FormItem label="控制灯光">
              <input v-model="lightBeltForm.color" type="color" />
            </FormItem>
            <FormItem label="灯效">
              <SelectDict
                v-model="lightBeltForm.effect"
                dict="effect"
                class="w-60"
              />
            </FormItem>
            <FormItem label="过渡">
              <InputNumber v-model="lightBeltForm.transition" clearable />
            </FormItem>
            <FormItem label="渐变">
              亮度：<InputNumber
                v-model="lightBeltForm.brightness_move"
                clearable
              />
              <span class="m-l-5">亮度变化：</span
              ><InputNumber v-model="lightBeltForm.brightness_step" clearable />
            </FormItem>
            <FormItem label="断电后清除状态">
              <RadioGroup v-model="lightBeltForm.do_not_disturb">
                <Radio label="1">是</Radio>
                <Radio label="0">否</Radio>
              </RadioGroup>
              <Button
                type="warning"
                size="small"
                icon="md-close"
                @click="lightBeltForm.do_not_disturb = null"
                v-if="lightBeltForm.do_not_disturb"
              ></Button>
            </FormItem>
          </Form>
          <Button
            type="info"
            @click="saveDownLight('lightBeltForm', lightBeltForm.id)"
            >设置</Button
          >
          <Button
            @click="
              resetForm('lightBeltForm', lightBeltForm.id, { color: '#2653D9' })
            "
            class="m-l-5"
            >重置</Button
          >
        </Card>
      </Col>
      <Col span="8">
        <Card title="控制设置" class="">
          <Form
            ref="ctrlForm"
            :model="ctrlForm"
            label-position="left"
            :label-width="60"
            label-colon
          >
            <FormItem label="开关">
              <RadioGroup v-model="ctrlForm.state">
                <Radio label="OPEN">打开</Radio>
                <Radio label="CLOSE">关闭</Radio>
                <Radio label="STOP">停止一切动作</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="方向">
              <Row>
                <Col span="4">点击速度：</Col>
                <Col span="20"
                  ><Slider
                    v-model="ctrlForm.options.motor_speed"
                    :step="1"
                    :min="0"
                    :max="255"
                /></Col>
              </Row>
              <div></div>
              <div>
                启用反转：
                <RadioGroup v-model="ctrlForm.options.reverse_direction">
                  <Radio label="1">是</Radio>
                  <Radio label="0">否</Radio>
                </RadioGroup>
              </div>
            </FormItem>
            <FormItem label="位置">
              <Slider
                v-model="ctrlForm.position"
                :step="1"
                :min="0"
                :max="100"
              />
            </FormItem>
          </Form>
          <Button type="info" @click="saveCtrl('ctrlForm', ctrlForm.id)"
            >设置</Button
          >
          <Button
            @click="resetForm('ctrlForm', ctrlForm.id, { options: {} })"
            class="m-l-5"
            >重置</Button
          >
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
import mqtt from "mqtt";
import { util } from "@/libs";
export default {
  data() {
    return {
      // 筒灯
      downLightForm: {
        color: "#2653D9",
        id: "0xa4c1389e998bf087",
      },
      deviceId: "0xa4c1389e998bf087",
      classroomId: "classroom_1001",
      // 灯带
      lightBeltForm: {
        color: "#2653D9",
        id: "0xa4c138f211287b7a",
      },
      // 控制
      ctrlForm: {
        options: {},
        id: "0xa4c138d598a2b617",
      },
    };
  },
  methods: {
    // 灯光控制
    saveDownLight(formName, id) {
      let params = {};
      this[formName].state ? (params.state = this[formName].state) : null;
      this[formName].brightness
        ? (params.brightness = this[formName].brightness)
        : null;
      this[formName].effect ? (params.effect = this[formName].effect) : null;
      this[formName].transition
        ? (params.transition = this[formName].transition)
        : null;
      this[formName].brightness_move
        ? (params.brightness_move = this[formName].brightness_move)
        : null;
      this[formName].brightness_step
        ? (params.brightness_step = this[formName].brightness_step)
        : null;
      this[formName].color_temp_move
        ? (params.color_temp_move = this[formName].color_temp_move)
        : null;
      this[formName].color_temp_step
        ? (params.color_temp_step = this[formName].color_temp_step)
        : null;
      this[formName].do_not_disturb
        ? (params.do_not_disturb =
            this[formName].do_not_disturb == "1" ? true : false)
        : null;
      if (this.downLightForm.colorMode == "xy") {
        this.downLightForm.colorTemp = null;
      }
      if (this.downLightForm.colorMode == "color_temp") {
        this.downLightForm.color = null;
        this.downLightForm.colorTemp = 153;
      }
      if (!this.downLightForm.colorMode) {
        this.downLightForm.color = null;
        this.downLightForm.colorTemp = null;
      }
      if (this[formName].color) {
        let color = util.set16ToRgb(this[formName].color, true);
        if (color.length)
          params.color = {
            r: color[0],
            g: color[1],
            b: color[2],
          };
      }
      if (this.downLightForm.colorTemp) {
        params.color_temp = this.downLightForm.colorTemp;
      }

      let paramStr = JSON.stringify(params);

      this.$data.client.publish(
        `zigbee/${this.classroomId}/${id}/set`,
        paramStr,
        { qos: 1 }
      );
    },
    resetForm(formName, id, params) {
      this.$refs[formName].resetFields();
      this[formName] = {
        id,
        ...params,
      };
    },
    saveCtrl(formName, id) {
      let params = {},
        options = {};
      this[formName].state ? (params.state = this[formName].state) : null;
      this[formName].position
        ? (params.position = this[formName].position)
        : null;
      if (this[formName].options) {
        this[formName].options.motor_speed
          ? (options.motor_speed = this[formName].options.motor_speed)
          : null;
        this[formName].options.reverse_direction
          ? (options.reverse_direction = this[formName].options
              .reverse_direction
              ? true
              : false)
          : null;
      }
      if (Object.keys(options).length > 0) {
        params.options = options;
      }
      let paramStr = JSON.stringify(params);
      // TODO:  未订阅basic_topic下的消息
      this.$data.client.publish(
        `basic_topic/${this.classroomId}/${id}/set`,
        paramStr,
        { qos: 1 }
      );
    },
  },
  created() {
    const options = {
      // 认证信息
      clientId: util.uuid(), //随机
      username: "demo_0", //⽤户id
      password: "zzzz1234", //密码
      clean: true, // true: 清除会话, false: 保留会话
      connectTimeout: 4000, // 超时时间
    };
    const client = mqtt.connect(`${process.env.VUE_APP_WEBSOCK}/mqtt`, options);

    // 是否链接
    client.on("connect", () => {
      console.log("MQTT链接成功！");
    });

    client.subscribe(`zigbee/${this.classroomId}/#`, { qos: 0 }); //添加⼀条消息订阅
    client.subscribe(`basic_topic/${this.classroomId}/#`, { qos: 0 }); //添加⼀条消息订阅
    //收到消息事件
    client.on("message", (topic, message) => {
      console.log(`${topic}: ${message.toString()}`);
      // console.log(`topic: ${topic}`)
      // if(topic.includes(this.deviceId)) {
      //   console.log(`${topic}: ${message.toString()}`)
      // }
    });
    //连接断开事件
    client.on("close", function() {});
    this.$data.client = client;
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.layout-config {
}
</style>
